<template>
  <!-- 禁用 naive-ui 的全局样式，参考 https://www.naiveui.com/zh-CN/os-theme/docs/style-conflict  -->
  <n-config-provider preflight-style-disabled>
    <DynamicDialog />

    <DebugFloatingBall />

    <div ref="entryRef">
      <Toast
        position="top-center"
        group="tc"
      />

      <Loading
        v-if="loading"
        class="h-[var(--ld-viewport-height)]"
      />

      <RouterView v-else />
    </div>
  </n-config-provider>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { h, onMounted, onUnmounted, ref } from 'vue'
import DynamicDialog from 'primevue/dynamicdialog'
import { useDialog } from 'primevue/usedialog'
import { NConfigProvider } from 'naive-ui'

import Confirm from '@/components/Confirm.vue'
import InputText from 'primevue/inputtext'
import Button from '@/components/Button.vue'
import { useCommonStore } from '@/stores'
import { getCurrentUser } from '@/api/auth'
import Loading from '@/components/Loading.vue'
import { useHotKey } from '@/hooks/index'
import EnergyBuy from '@/components/EnergyBuy.vue'
import DebugFloatingBall from '@/components/DebugFloatingBall.vue'

import type { Component, Ref } from 'vue'
import type { DialogProps } from 'primevue/dialog'
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import type {
  OpenDialogPromise,
  ConfirmOptions,
  InputDialogOptions,
} from '@/types/global'
import { fetchUserTags } from '@/api/user'
import { useRouter } from 'vue-router'
import bus, { BusEvent } from '@/bus/bus'
import RewardDialog from '@/components/RewardDialog.vue'
import type { Reward } from '@/api/task'
import { DEBUG_ROUTE_PREFIX } from '@/shared'
import Icon from '@/components/Icon.vue'

const toast = useToast()
const store = useCommonStore()
const router = useRouter()

const entrySize = ref({ width: 0, height: 0 })
const entryRef = ref<HTMLDivElement>()

function onWindowResize() {
  entrySize.value.width = entryRef.value?.clientWidth ?? 0
  entrySize.value.height = entryRef.value?.clientHeight ?? 0
}

window._viewSize = entrySize

onMounted(() => {
  onWindowResize()
  window.addEventListener('resize', onWindowResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
})

const loading = store.token !== '' ? ref(true) : ref(false)

// debug 路径忽略自动登录
if (!location.pathname.startsWith(DEBUG_ROUTE_PREFIX)) {
  if (loading.value) {
    onMounted(() => {
      // 尝试自动登录
      getCurrentUser(true)
        .then(res => {
          if (res.user) {
            store.login(store.token, res.user)
          }
        })
        .finally(() => {
          loading.value = false
        })
    })
  }
} else {
  loading.value = false
}

const messageFn = (type: 'error' | 'success' | 'info') => {
  return (content: string, title?: string) =>
    toast.add({
      severity: type,
      summary: title,
      detail: content,
      life: 3000,
      group: 'tc',
    })
}

window._message = {
  error: messageFn('error'),
  success: messageFn('success'),
  info: messageFn('info'),
}

let dialogInstances: DynamicDialogInstance[] = []
window.addEventListener('keydown', evt => {
  if (evt.code === 'Escape') {
    if (dialogInstances.length > 0) {
      dialogInstances[dialogInstances.length - 1].close()
    }
  }
})

const dialog = useDialog()

window._openDialog = function <R>(
  Comp: Component,
  options: {
    title?: string | Ref<string>
    props?: any
    dialog?: DialogProps
    rootClass?: string
  } = {}
): OpenDialogPromise<R> {
  const { title = '', props = {}, rootClass = '' } = options

  let instance: DynamicDialogInstance

  const titleRef = typeof title === 'string' ? ref(title) : title

  const dialogOptions = options.dialog || {}
  if (!dialogOptions.pt) {
    dialogOptions.pt = {}
  }
  if (!dialogOptions.pt.root) {
    dialogOptions.pt.root = {}
  }
  dialogOptions.pt.root.class = rootClass

  const result = new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h(Comp, {
          ...props,
          onDone: (val: R) => {
            resolve(val)
            instance.close()
          },
        })
      },
      {
        props: {
          modal: true,
          draggable: false,
          // esc 关闭我们自己实现，默认的实现会关闭全部 dialog
          closeOnEscape: false,
          closable: true,
          ...dialogOptions,
        },
        templates: {
          header: () =>
            h(
              'h2',
              {
                class: 'text-xl font-medium',
              },
              titleRef.value
            ),
        },
        onClose() {
          dialogInstances = dialogInstances.filter(i => i !== instance)
          resolve(undefined)
        },
      }
    )
    dialogInstances.push(instance)
  }) as OpenDialogPromise<R>

  result.close = () => instance.close()

  return result
}

window._confirm = function (options): Promise<boolean | undefined> {
  const confirmOptions = (
    typeof options === 'string'
      ? {
          type: 'confirm',
          content: options,
        }
      : options
  ) as ConfirmOptions

  const closeable =
    confirmOptions.type === 'notice' || confirmOptions.type === 'wechat'
  const dismissableMask = closeable

  const { onConfirm } = confirmOptions
  const loading = ref(false)

  let instance: DynamicDialogInstance

  return new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h(
          'div',
          {
            class: 'flex flex-col',
          },
          [
            closeable &&
              h(
                'div',
                {
                  class: `h-44px  items-center flex`,
                },
                h(Icon, {
                  class: 'w-20px ml-auto cursor-pointer',
                  name: 'close-circle',
                  onClick() {
                    resolve(undefined)
                    instance.close()
                  },
                })
              ),
            h(Confirm, {
              ...confirmOptions,
              onConfirm: () => {
                if (!onConfirm) {
                  resolve(true)
                  instance.close()
                  return
                }

                loading.value = true

                onConfirm()
                  .then((close: boolean) => {
                    if (close) {
                      resolve(true)
                      instance.close()
                    }
                  })
                  .finally(() => {
                    loading.value = false
                  })
              },

              onCancel: () => {
                resolve(false)
                instance.close()
              },
            }),
          ]
        )
      },
      {
        props: {
          modal: true,
          draggable: false,
          dismissableMask: dismissableMask,
          showHeader: false,
          pt: {
            content: {
              class: `mx-4 ${closeable ? 'mb-4' : 'my-4'} p-0 rounded-none`,
            },
            root: { class: 'g-dialog' },
          },
        },
        onClose() {
          dialogInstances = dialogInstances.filter(i => i !== instance)
          resolve(undefined)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._openInputDialog = function (options: InputDialogOptions) {
  let instance: DynamicDialogInstance

  const { onSubmit, placeholder, okText } = options
  const text = ref(options.text ?? '')
  const loading = ref(false)

  return new Promise<string | undefined>(resolve => {
    instance = dialog.open(
      () => {
        return h('div', [
          h(InputText, {
            class: 'w-full mb-4',
            modelValue: text.value,
            placeholder: placeholder,
            'onUpdate:modelValue': (val: string) => {
              text.value = val
            },
          }),
          h(Button, {
            class: 'w-full',
            label: okText,
            onClick: () => {
              loading.value = false

              onSubmit(text.value)
                .then((close: boolean) => {
                  if (close) {
                    resolve(text.value)
                    instance.close()
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            },
          }),
        ])
      },
      {
        props: {
          header: options.title,
          modal: true,
          draggable: false,
        },
        onClose() {
          resolve(undefined)
          dialogInstances = dialogInstances.filter(i => i !== instance)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._presentContent = function (
  Comp: Component,
  options?: {
    props?: any
    rootClass?: string
    contentClass?: string
  }
) {
  let contentClass = 'p-0'
  if (options?.contentClass) {
    contentClass += ` ${options.contentClass}`
  }

  return _openDialog(Comp, {
    props: options?.props,
    rootClass: options?.rootClass,
    dialog: {
      showHeader: false,
      dismissableMask: true,
      pt: {
        content: { class: contentClass },
      },
    },
  })
}

window._openEnergyBuy = function () {
  return new Promise(() => {
    _openDialog(EnergyBuy, {
      rootClass: 'w-500px',
      dialog: {
        showHeader: false,
        dismissableMask: true,
        pt: {
          content: {
            class: 'p-0',
          },
        },
      },
    })
  })
}

window._showRewards = function (rewards: Reward[]) {
  _openDialog(RewardDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-4' },
      },
    },
    props: {
      rewards,
    },
  })
}

let debugPanelOpen = false
window._openDebugPanel = function () {
  if (_global.isProd) return

  if (debugPanelOpen) {
    return
  }

  debugPanelOpen = true
  import('@/components/DebugPanel/DebugPanel.vue').then(mod => {
    _openDialog(mod.default, { title: '调试面板' })
      .then(() => {
        debugPanelOpen = false
      })
      .catch(() => {
        location.reload()
      })
  })
}

window._closeAllDialogs = function () {
  dialogInstances.forEach(i => i.close())
  dialogInstances = []
}

window._closeActiveDialog = function () {
  const active = dialogInstances.pop()
  active?.close()
}

// mount DebugPanel
if (!_global.isProd) {
  useHotKey('f1', window._openDebugPanel)
}

function onLogin() {
  fetchUserTags().then(res => {
    if (res.identityTagResponses.length === 0) {
      router.push({
        name: 'identity-onboarding',
      })
      return
    }
  })
}

onMounted(() => {
  bus.on(BusEvent.Login, onLogin)
  window.addEventListener('popstate', _closeAllDialogs)
})

onUnmounted(() => {
  bus.off(BusEvent.Login, onLogin)
  window.removeEventListener('popstate', _closeAllDialogs)
})
</script>

<style></style>
