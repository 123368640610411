<template>
  <div class="w-full">
    <Tree
      :nodes="chapters"
      :selection-keys="selectedKeys"
      :expanded-keys="data.expandedKeys"
      :draggable="isOwner"
      :draggingOverNode="data.dragOverNode"
      :draggingOverPosition="data.dragOverPosition"
      class="p-2"
      @update:selection-keys="onSelectedKeysUpdate"
      @update:expanded-keys="onExpandedKeysUpdate"
      @node-expand="onNodeExpand"
      @drag-start="onDragStart"
      @drag-enter="onDragEnter"
      @drag-leave="onDragLeave"
      @drag-end="onDragEnd"
    >
      <template #default="{ node }">
        <div
          v-if="node.type === 'chapter'"
          class="overflow-hidden flex items-center w-full"
        >
          <div class="flex-1 flex items-center">
            <span class="truncate">
              {{ node.label }}
            </span>
            <span class="text-xs ml-auto">
              ({{ allChapterCountMap[node.key] }})
            </span>
          </div>

          <ChapterMenu
            v-if="isOwner && !node.root"
            :root="node.root"
            @rename="onChapterRename(node.key!)"
            @delete="onChapterDelete(node.key!)"
          />
        </div>

        <div
          v-else
          class="overflow-hidden flex items-center w-full"
        >
          <div class="flex-1 flex items-center overflow-hidden">
            <div
              v-if="showDuplicateTag(node)"
              class="duplicate"
            >
              {{ _t('副本') }}
            </div>
            <span class="truncate">
              {{ node.label }}
            </span>
          </div>
        </div>
      </template>
    </Tree>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import * as api from '@/api/package-source'
import {
  getChapterAncestors,
  getAllChapterTotalCardCount,
  genChapterTree,
} from '@/utils/package'
import { getCardTextDigest } from '@/utils/card'
import ChapterMenu from './ChapterMenu.vue'
import { useCommonStore } from '@/stores'
import Tree from '@/components/Tree/Tree.vue'
import { ROOT_CHAPTER_KEY } from '@/types/core'

import type { TreeNode, DragPosition } from '@/components/Tree/Tree.vue'

interface MyTreeNode extends TreeNode {
  parentKey: string
  type: 'card' | 'chapter'
  card?: api.CardResponse

  children?: MyTreeNode[]
}

const treeNodeMap = new Map<string, MyTreeNode>()

const props = defineProps<{
  package: api.Package
  selectedCardId?: string
  selectedChapterId: string
  chapterCardsMap: Record<string, api.CardResponse[]>
}>()

const emit = defineEmits<{
  renameChapter: [api.ChapterItem]
  deleteChapter: [string]
  chapterSelect: [string]
  cardSelect: [number | undefined]
  moveChapter: [api.MoveChapterRequest]
  fetchCards: [chapterId: string]
}>()

const store = useCommonStore()

const packageState = store.packageState[props.package.id] ?? {}

const chapters = computed<MyTreeNode[]>(() => genMyChapterTree(props.package))

const isOwner = computed(() => props.package.owned != null)

const data = reactive({
  expandedKeys: packageState.expandedKeys ?? {
    root: true,
  },
  draggingNode: undefined as MyTreeNode | undefined,
  dragOverNode: undefined as MyTreeNode | undefined,
  dragOverPosition: undefined as DragPosition | undefined,
})

const allChapterCountMap = computed(() =>
  getAllChapterTotalCardCount(props.package)
)

const selectedKeys = computed(() => {
  if (props.selectedCardId) {
    return { ['card-' + props.selectedCardId]: true }
  }

  return props.selectedChapterId ? { [props.selectedChapterId]: true } : {}
})

store.$subscribe((_mutation, state) => {
  if (state.cardEditEvent) {
    const [cardId, card] = state.cardEditEvent

    const node = getTreeNode(`card-${cardId}`)
    if (node) {
      node.card = card
    }
  }
})

// 初始化 「目录树」的状态，如展开，选中，以及获取相应的 card list 节点
async function initTree() {
  if (props.selectedChapterId) {
    const ancestors = getChapterAncestors(
      props.package.chapters,
      props.selectedChapterId
    )

    for (const item of ancestors) {
      data.expandedKeys[item] = true
    }
  }

  for (const chapterId in data.expandedKeys) {
    if (data.expandedKeys[chapterId]) {
      const chapterNode = getTreeNode(chapterId)

      if (chapterNode) {
        await onNodeExpand(chapterNode)
      }
    }
  }
}

function showDuplicateTag(node: MyTreeNode) {
  const card = node.card as api.CardResponse

  return (
    card.createdType === api.CardCreatedType.DUPLICATE &&
    card.createdAt === card.updatedAt
  )
}

function getTreeNode(key: string) {
  return treeNodeMap.get(key)
}

// card-1001 ---> 1001
function getCardId(key: string): number {
  const slashIndex = key.indexOf('-')
  const cardId = key.slice(slashIndex + 1)

  return Number(cardId)
}

async function onSelectedKeysUpdate(selectionKeys: Record<string, boolean>) {
  const keys = Object.keys(selectionKeys)

  if (keys.length === 0) {
    return
  }

  const selectedKey = keys[0]
  if (selectedKey.startsWith('card')) {
    const cardId = getCardId(selectedKey)
    emit('cardSelect', cardId)
    const node = getTreeNode(selectedKey)

    if (node?.parentKey) {
      emit('chapterSelect', node.parentKey)
    }
  } else {
    emit('chapterSelect', keys[0])
    emit('cardSelect', undefined)
  }
}

async function onChapterRename(chapterId: string) {
  const chapter = props.package.chapters[chapterId]

  await _openInputDialog({
    title: _t('重命名'),
    text: chapter.title,
    placeholder: _t(chapter.title),
    okText: _t('保存'),
    onSubmit: async newTitle => {
      const res = await api.updateChapter(props.package.id, chapterId, {
        title: newTitle.trim(),
        parentId: chapter.parentId
          ? String(chapter.parentId)
          : ROOT_CHAPTER_KEY,
      })

      if (res.code !== 0) {
        _message.info(res.message)
        return false
      }

      emit('renameChapter', res.data)

      return true
    },
  })
}

async function onChapterDelete(chapterId: string) {
  const chapter = props.package.chapters[chapterId]
  const res = await _confirm({
    title: `删除章节 ${chapter.title}`,
    type: 'warn',
    content: '删除后无法恢复，请确认',
    okText: '删除',
    cancelText: '暂不',
  })

  if (res) {
    const res = await api.deleteChapter(props.package.id, chapterId)

    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    emit('deleteChapter', chapterId)
  }
}

function onDragEnter(node: MyTreeNode, position: DragPosition) {
  if (!data.draggingNode) return

  // 不能放在卡片里面
  if (node.type === 'card' && position === 'in') return

  data.dragOverNode = node
  data.dragOverPosition = position
}

function onDragLeave() {
  data.dragOverNode = undefined
  data.dragOverPosition = undefined
}

function onDragStart(node: MyTreeNode) {
  data.draggingNode = node
}

function moveTreeNode({
  key,
  beforeNodeKey,
  sourceParentKey,
  targetParentKey,
}: {
  key: string
  beforeNodeKey?: string
  sourceParentKey: string
  targetParentKey: string
}) {
  const sourceNode = getTreeNode(key)
  const sourceParentChildren = getTreeNode(sourceParentKey)?.children ?? []
  const targetParentChildren = getTreeNode(targetParentKey)?.children ?? []

  if (!sourceNode || !sourceParentChildren || !targetParentChildren) return

  const index = sourceParentChildren.findIndex(item => item.key === key) ?? -1

  if (index > -1) {
    sourceParentChildren!.splice(index, 1)

    if (beforeNodeKey) {
      const index = targetParentChildren.findIndex(
        item => item.key === beforeNodeKey
      )

      if (index > -1) {
        targetParentChildren.splice(index, 0, sourceNode)
      }
    } else {
      targetParentChildren.push(sourceNode)
    }
    sourceNode.parentKey = targetParentKey
  }
}

function moveCard(
  sourceNode: MyTreeNode,
  targetNode: MyTreeNode,
  position: DragPosition
) {
  if (sourceNode.type !== 'card') return

  if (sourceNode.key === targetNode.key) return

  if (targetNode.type === 'card') {
    api.moveCard({
      sourcePkgId: props.package.id,
      cardId: sourceNode.card!.id,
      beforeCardId: position === 'bottom' ? undefined : targetNode.card!.id,
      sourceChapterId: sourceNode.parentKey,
      targetChapterId: targetNode.parentKey,
    })

    moveTreeNode({
      key: sourceNode.key,
      beforeNodeKey: position === 'bottom' ? undefined : targetNode.key,
      sourceParentKey: sourceNode.parentKey,
      targetParentKey: targetNode.parentKey,
    })
  } else {
    const targetChapterId =
      position === 'in' ? targetNode.key : targetNode.parentKey

    const targetChapterNode = getTreeNode(targetChapterId)
    const beforeCardId = targetChapterNode?.children?.find(
      item => item.type === 'card'
    )?.card?.id

    api.moveCard({
      sourcePkgId: props.package.id,
      cardId: sourceNode.card!.id,
      beforeCardId: beforeCardId,
      sourceChapterId: sourceNode.parentKey,
      targetChapterId: targetChapterId,
    })

    moveTreeNode({
      key: sourceNode.key,
      beforeNodeKey: beforeCardId ? `card-${beforeCardId}` : undefined,
      sourceParentKey: sourceNode.parentKey,
      targetParentKey: targetChapterId,
    })
  }
}

function moveChapter(
  sourceNode: MyTreeNode,
  targetNode: MyTreeNode,
  position: DragPosition
) {
  if (sourceNode.type !== 'chapter') return

  if (sourceNode.key === targetNode.key) return

  const targetChapterId =
    targetNode.type === 'card'
      ? targetNode.parentKey
      : position === 'in'
        ? targetNode.key
        : targetNode.parentKey

  const targetChapterNode = getTreeNode(targetChapterId)
  const firstChildChapter = targetChapterNode?.children?.find(
    item => item.type === 'chapter'
  )
  const beforeChapterId =
    targetNode.type === 'card' || position === 'bottom'
      ? undefined
      : position === 'in'
        ? firstChildChapter?.key
        : targetNode.key

  const request: api.MoveChapterRequest = {
    sourcePkgId: props.package.id,
    chapterId: sourceNode.key,
    beforeChapterId,
    parentChapterId: targetChapterId,
  }

  api.moveChapter(request)
  emit('moveChapter', request)

  moveTreeNode({
    key: sourceNode.key,
    beforeNodeKey: beforeChapterId,
    sourceParentKey: sourceNode.parentKey,
    targetParentKey: targetChapterId,
  })
}

function onDragEnd() {
  if (!data.dragOverNode || !data.dragOverPosition) return

  if (data.draggingNode?.type === 'card') {
    moveCard(data.draggingNode, data.dragOverNode, data.dragOverPosition)
  } else if (data.draggingNode?.type === 'chapter') {
    moveChapter(data.draggingNode, data.dragOverNode, data.dragOverPosition)
  }

  data.dragOverNode = undefined
  data.dragOverPosition = undefined
}

async function onNodeExpand(node: TreeNode) {
  if (node.loading) return

  if (!props.chapterCardsMap[node.key]) {
    emit('fetchCards', node.key)
  }
}

function onExpandedKeysUpdate(expandedKeys: Record<string, boolean>) {
  data.expandedKeys = expandedKeys
  store.updatePackageState(props.package.id, {
    expandedKeys,
  })
}

function cardToTreeNode(card: api.CardResponse, parentKey: string): MyTreeNode {
  return {
    key: `card-${card.id}`,
    label: getCardTextDigest(card.content),
    type: 'card',
    leaf: true,
    parentKey,
    card,
  }
}

function genMyChapterTree(pkg: api.Package): MyTreeNode[] {
  const tree = genChapterTree(`${pkg.name}`, pkg.chapters)

  function toMyTreeNode(node: TreeNode, parentId: string): MyTreeNode {
    const cards = props.chapterCardsMap[node.key] ?? []
    const cardNodes = cards.map(item => cardToTreeNode(item, node.key))

    cardNodes.forEach(n => {
      treeNodeMap.set(n.key, n)
    })

    const myTreeNode: MyTreeNode = {
      key: node.key,
      type: 'chapter',
      label: node.label,
      children: node.children
        ? [...node.children.map(n => toMyTreeNode(n, node.key)), ...cardNodes]
        : [...cardNodes],
      leaf: false,
      root: node.root,
      parentKey: parentId,
    }

    treeNodeMap.set(myTreeNode.key, myTreeNode)
    return myTreeNode
  }

  const myTree = toMyTreeNode(tree, '')

  if (props.chapterCardsMap[ROOT_CHAPTER_KEY]) {
    const rootCardNodes = props.chapterCardsMap[ROOT_CHAPTER_KEY].map(item =>
      cardToTreeNode(item, ROOT_CHAPTER_KEY)
    )

    for (const node of rootCardNodes) {
      if (!myTree.children!.some(item => item.key === node.key)) {
        myTree.children?.push(node)
      }
    }
  }

  return [myTree]
}

onMounted(() => {
  initTree()
})
</script>

<style>
.treenode {
  position: relative;

  .menu-trigger {
    opacity: 0;
    text-align: right;
    height: 100%;
    margin-left: 4px;
  }
}

.treenode:hover {
  .menu-trigger {
    opacity: 1;
  }
}

.duplicate {
  background-color: var(--gray-400);
  border-radius: 8px;
  font-size: 12px;
  color: white;
  padding: 2px 4px;
  margin-right: 6px;
  white-space: nowrap;
}
</style>
